import { createReducer, on } from '@ngrx/store';
import { initialAlertsState } from '../states/alertsState';
import { addToAlerts } from '../actions/alerts.action';

export const alertsReducer = createReducer(
  initialAlertsState,
  on(addToAlerts, (state, action) => {
    return { ...state, alerts: action.alerts, isLoaded: action.isLoaded };
  })
);
