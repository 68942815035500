import { Alert } from '@app/core/models/alert.model';

export interface AlertsState {
  alerts: Alert[];
  isLoaded: boolean;
}

export const initialAlertsState: AlertsState = {
  alerts: [],
  isLoaded: false,
};
