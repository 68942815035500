<div
  class="w-full relative bg-gray-50 flex flex-col items-start justify-start text-left text-lg text-gray-800 font-display-sm-bold"
>
  <div
    class="self-stretch flex flex-col items-start justify-center py-6 px-4 md:px-16 text-sm text-gray-true-800"
  >
    <div class="self-stretch flex flex-col items-start justify-center gap-[12px_0px]">
      <h1
        class="self-stretch relative text-11xl leading-[38px] font-bold"
        *ngIf="category?.label !== 'Undefined'"
      >
        {{ category?.label }}
      </h1>
      <div
        *ngIf="category?.description"
        [innerHTML]="category?.description"
        class="ngx-editor-content"
      ></div>
      <div class="self-stretch flex flex-row items-center justify-between text-gray-700">
        <div class="flex flex-row relative items-center justify-start gap-[0px_8px]">
          <app-range-filter
            *ngIf="pageLoaded"
            [label]="'Price'"
            [menuId]="'priceMenu'"
            [buttonId]="'priceButton'"
            [currencySymbol]="configuration?.tenantCurrencySymbol"
            (applyPriceFilter)="addPriceFilters($event)"
          />

          <app-outer-filter
            *ngIf="collectionGroups?.length > 0"
            [label]="'Sub Categories'"
            [optionsItems]="collectionGroups"
            [menuId]="'subCategoriesMenu'"
            [buttonId]="'subCategoriesButton'"
            [multipleSelect]="true"
            [removedFilters]="removedFilters"
            (selectedFilterOptions)="addGroupsFilters($event)"
          />

          <app-outer-filter
            *ngIf="brandsFilters?.length > 0"
            [label]="'Brands'"
            [optionsItems]="brandsFilters"
            [menuId]="'brandMenu'"
            [buttonId]="'brandButton'"
            [multipleSelect]="true"
            [removedFilters]="removedFilters"
            (selectedFilterOptions)="addBrandsFilters($event)"
          />

          <!-- <div
            class="rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] border-[1px] border-solid border-gray-300 hover:cursor-pointer"
            (click)="openDrawer('filter')"
          >
            <img
              class="w-5 relative h-5 overflow-hidden shrink-0"
              alt="filter lines"
              src="assets/images/category/filterlines.svg"
            />

            <div class="flex flex-row items-center justify-center py-0 px-0.5">
              <div class="relative leading-[20px] font-semibold">More Filters</div>
            </div>
          </div> -->
        </div>
        <div class="flex flex-col-reverse">
          <app-outer-filter
            *ngIf="sortFilters?.length > 0"
            [label]="'Sort by'"
            [optionsItems]="sortFilters"
            [menuId]="'sortFilter'"
            [buttonId]="'sortFilterButton'"
            [removedFilters]="removedFilters"
            (selectedFilterOptions)="addSortFilters($event)"
          />
        </div>
      </div>
      <div
        class="flex flex-row items-center justify-start gap-[0px_12px] text-center text-brand-700"
      >
        <div
          class="rounded-2xl bg-brand-50 flex flex-row items-center justify-start py-1 pr-1.5 pl-3 gap-[0px_2px] border-[1px] border-solid border-brand-200"
          *ngIf="selectedPriceFiltersLabel"
        >
          <div class="relative leading-[20px]">{{ selectedPriceFiltersLabel }}</div>
          <div
            class="rounded-10xs overflow-hidden flex flex-col items-start justify-start p-0.5 mix-blend-darken cursor-pointer"
            (click)="removePriceFilters()"
          >
            <img
              class="w-3 relative h-3 overflow-hidden shrink-0"
              alt="close icon"
              src="assets/images/category/xclose.svg"
            />
          </div>
        </div>
        <div
          class="rounded-2xl bg-brand-50 flex flex-row items-center justify-start py-1 pr-1.5 pl-3 gap-[0px_2px] border-[1px] border-solid border-brand-200"
          *ngFor="let brandFilter of selectedBrandsFilters"
        >
          <div class="relative leading-[20px]">{{ brandFilter.label }}</div>
          <div
            class="rounded-10xs overflow-hidden flex flex-col items-start justify-start p-0.5 mix-blend-darken cursor-pointer"
            (click)="removeBrandFilters(brandFilter)"
          >
            <img
              class="w-3 relative h-3 overflow-hidden shrink-0"
              alt="close icon"
              src="assets/images/category/xclose.svg"
            />
          </div>
        </div>
        <div
          class="rounded-2xl bg-brand-50 flex flex-row items-center justify-start py-1 pr-1.5 pl-3 gap-[0px_2px] border-[1px] border-solid border-brand-200"
          *ngFor="let groupFilter of selectedGroupsFilters"
        >
          <div class="relative leading-[20px]">{{ groupFilter.label }}</div>
          <div
            class="rounded-10xs overflow-hidden flex flex-col items-start justify-start p-0.5 mix-blend-darken cursor-pointer"
            (click)="removeGroupsFilters(groupFilter)"
          >
            <img
              class="w-3 relative h-3 overflow-hidden shrink-0"
              alt="close icon"
              src="assets/images/category/xclose.svg"
            />
          </div>
        </div>
        <div
          class="rounded-2xl bg-brand-50 flex flex-row items-center justify-start py-1 pr-1.5 pl-3 gap-[0px_2px] border-[1px] border-solid border-brand-200"
          *ngFor="let sortFilter of selectedSortFilters"
        >
          <div class="relative leading-[20px]">{{ sortFilter.label }}</div>
          <div
            class="rounded-10xs overflow-hidden flex flex-col items-start justify-start p-0.5 mix-blend-darken cursor-pointer"
            (click)="removeSortFilters(sortFilter)"
          >
            <img
              class="w-3 relative h-3 overflow-hidden shrink-0"
              alt="close icon"
              src="assets/images/category/xclose.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="items?.length > 0"
    class="self-stretch items-center justify-center pt-0 px-2 pb-2 gap-[8px_8px] md:px-16 md:pb-12 md:gap-[32px_32px] cursor-pointer grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5"
    id="frameContainerCategory2"
  >
    <ng-container *ngFor="let item of items">
      <app-product-item
        [colectionId]="category?.id"
        [item]="item"
        (onUpdateProduct)="onUpdateProduct($event)"
        (openAddressDrawer)="openDrawerLoc('addresses')"
        (openLoginDrawer)="openDrawerLoc('login')"
      />
    </ng-container>
    <!-- <div
      class="w-[242px] rounded-lg bg-base-white box-border shrink-0 flex flex-col items-start justify-start pt-0 px-0 pb-3 gap-[4px] z-[0] border-[1px] border-solid border-gray-2001 duration-700 ease-in-out overflow-hidden"
      *ngFor="let item of items"
    >
      <app-product-item
        [item]="item"
        (openAddressDrawer)="openDrawerLoc('addresses')"
        (openLoginDrawer)="openDrawerLoc('login')"
      />
    </div> -->
  </div>
  <!-- <div class="self-stretch items-center justify-center pt-0 px-16 pb-12">
    <div class="self-stretch flex flex-row items-center justify-between">
      <a
        href="#"
        class="flex items-center justify-center px-4 h-10 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
      >
        Previous
      </a>
      <span class="flex-grow"></span>
      <a
        href="#"
        class="flex items-center justify-center px-4 h-10 ms-3 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
      >
        Next
      </a>
    </div>
  </div> -->
</div>
<div class="bg-gray-50 pb-12" *ngIf="apiResponse && items?.length === 0">
  <div class="flex-1 flex flex-col items-center">
    <div class="flex-grow text-center">
      <img
        class="w-24 h-24 ml-[27px] mb-5 relative overflow-hidden shrink-0"
        alt="no product found icon"
        src="assets/images/product/product-icon.svg"
        id="productIcon"
      />
      <h1 class="font-bold text-5xl">Sorry !</h1>
      <span class="text-lg">No products found.</span>
    </div>
  </div>
</div>
<div
  class="self-stretch bg-gray-50 flex flex-row items-center justify-between pt-4 md:pt-0 px-2 md:px-16 pb-6 md:pb-12 text-base text-gray-700"
>
  <img class="w-12 relative rounded-3xs h-12 opacity-[0]" alt="s" src="" />

  <div
    *ngIf="showMoreBtn"
    (click)="loadMore()"
    class="rounded-lg bg-base-white cursor-pointer shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-3 px-[18px] gap-[0px_6px] border-[1px] border-solid border-gray-300"
  >
    <div class="flex flex-row items-center justify-center py-0 px-0.5">
      <div class="relative leading-[24px] font-semibold">Load more</div>
    </div>
  </div>
  <img
    *ngIf="isVisible"
    (click)="scrollTop()"
    class="w-12 relative rounded-3xs h-12 cursor-pointer"
    alt="top arrow icon"
    src="assets/images/home/top-arrow-icon.svg"
    id="featuredIcon1"
  />
</div>

<app-drawer
  *ngIf="pageLoaded"
  [drawerId]="'categoryPageDrawer'"
  [isOpen]="isDrawerOpen"
  (reset)="reset()"
>
  <app-more-filters
    [selectedGroupsFilters]="selectedGroupsFilters"
    [selectedBrandsFilters]="selectedBrandsFilters"
    [brandsFilters]="brandsFilters"
    [groupsFilters]="collectionGroups"
    *ngIf="isFilterOpen"
    (isMoreFiltersOpen)="reset()"
    (applyMoreFilter)="applyMoreFilter($event)"
  />
  <app-add-location
    *ngIf="isLocationOpen"
    (closeDrawer)="closeLocation($event)"
    (onSubmit)="saveLocation($event)"
  />
  <app-address-list
    *ngIf="isAddressesOpen"
    [eddressCode]="deliveryAddress?.eddressCode"
    (closeDrawer)="closeAddresses($event)"
    (newLocation)="openDrawerLoc('location')"
    (confirmLocation)="getDeliveryAddress($event)"
  ></app-address-list>

  <app-login
    *ngIf="isLoginDrawerOpen"
    (isLoginOpen)="closeLogin($event)"
    (isSignupOpen)="openDrawer('signup')"
    (isForgotPasswordOpen)="openDrawer('forgotPassword')"
  />

  <app-signup
    *ngIf="isSignupDrawerOpen"
    (isSignupOpen)="closeSignup($event)"
    (isLoginOpen)="openDrawer('login')"
  />
  <app-forgot-password
    *ngIf="isForgotPasswordDrawerOpen"
    (isForgotPasswordOpen)="closeLogin($event)"
    (isLoginOpen)="openDrawer('login')"
  />
</app-drawer>
