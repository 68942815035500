import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShowAlertService {
  public alertQueue: string[] = [];
  private currentAlertSubject = new BehaviorSubject<string | null>(null);
  currentAlert$ = this.currentAlertSubject.asObservable();

  addAlert(message): void {
    this.alertQueue.push(message);
    this.showNextAlert();
  }

  private showNextAlert(): void {
    if (!this.currentAlertSubject.value && this.alertQueue.length > 0) {
      const nextAlert = this.alertQueue.shift()!;
      this.currentAlertSubject.next(nextAlert);
    }
  }

  closeAlert(): void {
    this.currentAlertSubject.next(null);
    this.showNextAlert();
  }
}
