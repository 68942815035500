import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { Dropdown } from 'flowbite';
import type { DropdownOptions, DropdownInterface } from 'flowbite';
import type { InstanceOptions } from 'flowbite';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-outer-filter',
  templateUrl: './outer-filter.component.html',
  styleUrl: './outer-filter.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf],
})
export class OuterFilterComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() label;
  @Input() optionsItems = [];

  @Input() menuId;
  @Input() buttonId;

  @Input() multipleSelect = false;

  @Input() removedFilters = [];

  @Output() selectedFilterOptions = new EventEmitter<any[]>();
  @Output() disselectedFilterOptions = new EventEmitter<any[]>();

  selectedFilters = [];
  disselectedFilters = [];

  previousValue = '';

  dropdown: DropdownInterface;

  options: DropdownOptions = {
    placement: 'bottom',
    triggerType: 'click',
    offsetSkidding: 0,
    offsetDistance: 10,
    delay: 300,
    onHide: () => {
      // console.log('dropdown has been hidden');
    },
    onShow: () => {
      // console.log('dropdown has been shown');
    },
    onToggle: () => {
      // console.log('dropdown has been toggled');
    },
  };

  instanceOptions: InstanceOptions = {
    id: '',
    override: true,
  };

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['removedFilters']) {
      this.removedFilters.forEach((item) => {
        const index = this.selectedFilters.indexOf(item);
        if (index !== -1) {
          this.selectedFilters.splice(index, 1);
        }
      });
    }
  }

  ngAfterViewInit() {
    this.instanceOptions.id = this.menuId;

    const $targetEl: HTMLElement = document.getElementById(this.menuId);
    const $triggerEl: HTMLElement = document.getElementById(this.buttonId);

    this.dropdown = new Dropdown($targetEl, $triggerEl, this.options, this.instanceOptions);
  }

  addRemoveFilterOption(value) {
    if (this.selectedFilters.includes(value)) {
      const index = this.selectedFilters.indexOf(value);
      if (index !== -1) {
        this.selectedFilters.splice(index, 1);
        this.disselectedFilters.push(value);
      }
    } else {
      const index = this.disselectedFilters.indexOf(value);
      if (index !== -1) {
        this.disselectedFilters.splice(index, 1);
      }

      if (this.multipleSelect) {
        this.selectedFilters.push(value);
      } else {
        const index = this.selectedFilters.indexOf(this.previousValue);
        this.selectedFilters.splice(index, 1);
        this.selectedFilters.push(value);
        this.disselectedFilters.push(this.previousValue);
      }
    }
    this.previousValue = value;

    this.selectedFilterOptions.emit(this.selectedFilters);
    this.disselectedFilterOptions.emit(this.disselectedFilters);
  }

  onSelect(event) {
    this.selectedFilters;
    if (!this.multipleSelect) {
      this.optionsItems.forEach((o) => (o.selected = false));
      event.selected = true;
      this.selectedFilterOptions.emit(event);
    } else {
      event.selected = !event.selected;
      const selectedItems = this.optionsItems.filter((o) => o.selected);
      this.selectedFilterOptions.emit(selectedItems);
    }
  }
}
