<div
  class="flex-1 bg-white overflow-hidden flex flex-col items-center justify-start gap-[24px_0px] border-l-[1px] border-solid border-gray-200"
>
  <div
    class="self-stretch flex flex-row items-start justify-start py-0 px-6 relative gap-[0px_8px]"
  >
    <div
      class="flex-1 flex flex-row items-center justify-start pt-6 px-0 pb-0 gap-[0px_16px] z-[0]"
    >
      <img
        class="w-10 relative rounded-lg h-10"
        alt="feature icon"
        src="assets/images/home/featured-icon.svg"
      />

      <div class="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
        <div class="self-stretch relative leading-[30px] font-semibold">Cart</div>
        <div class="w-[376px] relative text-sm leading-[20px] text-gray-600 hidden">
          Lorem ipsum dolor sit amet.
        </div>
      </div>
    </div>
    <div
      (click)="close()"
      class="w-10 my-0 mx-[!important] absolute top-[12px] right-[16px] rounded-lg h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1]"
    >
      <img
        class="w-6 relative h-6 overflow-hidden shrink-0 cursor-pointer"
        alt="close icon"
        src="assets/images/home/xclose.svg"
      />
    </div>
  </div>
  <div
    class="self-stretch flex flex-col items-start justify-start py-0 px-6 box-border gap-[10px_0px] text-xs text-gray-800"
  >
    <div
      class="self-stretch flex flex-col items-start justify-start text-center text-sm text-gray-700"
    >
      <div class="self-stretch flex flex-row items-center justify-between">
        <div
          class="rounded-md bg-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] flex flex-row items-center justify-start py-0.5 px-2 gap-[0px_6px] border-[1px] border-solid border-gray-300"
        >
          <img class="w-2 relative h-2" alt="cart items icon" src="assets/images/home/-dot.svg" />

          <div class="relative leading-[20px]">{{ cartItemCount || 0 }} items</div>
        </div>
        <div class="relative text-xl leading-[30px] font-semibold text-gray-900 text-left">
          {{ configuration?.tenantCurrencySymbol }}{{ grandTotal || 0 | number : "1.2-2" }}
        </div>
      </div>
    </div>
    <div
      class="flex flex-col items-center justify-center"
      *ngIf="selectedStore.minimumCharge && grandTotal < selectedStore.minimumCharge"
    >
      <div class="flex-grow text-center">
        <span class="text-base"
          >Minimum charge: {{ configuration?.tenantCurrencySymbol
          }}{{ selectedStore.minimumCharge | number : "1.2-2" }}</span
        >
      </div>
    </div>
    <!-- <div class="self-stretch relative bg-gray-201 h-px"></div> -->
    <div class="self-stretch relative flex flex-row-reverse">
      <span
        class="text-red-500 underline cursor-pointer justify-end"
        *ngIf="products?.length > 0"
        (click)="clearAllBasket()"
        >Clear All</span
      >
    </div>
    <div
      *ngFor="let product of products"
      class="self-stretch rounded-lg bg-white flex flex-row items-center justify-start gap-[24px_4px] border-[1px] border-solid border-gray-201"
    >
      <img
        class="w-[100px] rounded-3xs h-[100px] object-cover"
        alt="product image"
        [src]="product?.product?.imageUrl"
      />

      <div class="flex-1 flex flex-col items-start justify-start py-1 px-3 gap-[4px_0px]">
        <div class="self-stretch relative leading-[18px] font-semibold">
          {{ product?.product?.label }}
        </div>
        <!-- <div class="self-stretch relative leading-[18px]">
          {{ product.quantity }}
        </div> -->
        <div
          class="self-stretch flex flex-row items-center justify-between text-base text-brand-500 gap-[0px_4px]"
        >
          <div class="flex flex-row items-center justify-start">
            <b class="relative leading-[24px]"
              >{{ configuration?.tenantCurrencySymbol
              }}{{ product?.product?.price | number : "1.2-2" }}</b
            >
          </div>
          <div class="w-[74px] relative h-7 text-white">
            <div
              class="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-mid bg-brand-500"
            ></div>
            <b
              class="absolute h-4/5 w-[8.43%] top-[7.14%] left-[44.59%] leading-[24px] inline-block"
              >{{ product?.quantity }}</b
            >
            <img
              (click)="addQuantity(product)"
              class="absolute h-[59.82%] w-[21.62%] top-[21.43%] right-[6.76%] bottom-[18.75%] left-[71.62%] max-w-full overflow-hidden max-h-full cursor-pointer"
              alt="add product icon"
              src="assets/images/home/plus.svg"
            />
            <img
              class="absolute h-[60.5%] w-[21.62%] top-[21.43%] right-[70.27%] bottom-[18.07%] left-[8.11%] max-w-full overflow-hidden max-h-full cursor-pointer"
              alt="trash icon"
              src="assets/images/view-cart/trash03.svg"
              *ngIf="product?.product?.quantity === 1"
              (click)="removeItem(product)"
            />

            <img
              (click)="removeQuantity(product)"
              class="absolute h-[60.5%] w-[21.62%] top-[21.43%] right-[70.27%] bottom-[18.07%] left-[8.11%] max-w-full overflow-hidden max-h-full cursor-pointer"
              alt="remove image icon"
              src="assets/images/home/minus.svg"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col items-center justify-center gap-[50px]"
      *ngIf="!products || products?.length === 0"
    >
      <div class="flex-grow text-center">
        <img
          class="w-24 h-24 ml-[100px] mb-5 relative overflow-hidden shrink-0"
          alt="shopping cart image"
          src="assets/images/home/shoppingcart.svg"
          id="shoppingCartIcon"
        />
        <h1 class="font-bold text-lg">YOUR CART IS EMPTY :(</h1>
        <span class="text-base">Looks like you haven't added anything here yet.</span>
      </div>
    </div>
    <div
      class="flex flex-col items-center justify-center"
      *ngIf="selectedStore.minimumTicketFreeDelivery && grandTotal >= selectedStore.minimumCharge"
    >
      <div class="flex-grow text-center">
        @if(grandTotal < selectedStore.minimumTicketFreeDelivery &&
        selectedStore.minimumTicketFreeDelivery - grandTotal <= 10 ) {
        <span class="text-base"
          >Add
          <span class="font-semibold"
            >{{ configuration?.tenantCurrencySymbol
            }}{{ selectedStore.minimumTicketFreeDelivery - grandTotal | number : "1.2-2" }}</span
          >
          for <span class="font-semibold">Free delivery</span>
          <span>😎</span>
        </span>
        } @else if(grandTotal < selectedStore.minimumTicketFreeDelivery){
        <span class="text-base"
          ><span class="font-semibold">Free delivery</span> for baskets >
          <span class="font-semibold"
            >{{ configuration?.tenantCurrencySymbol
            }}{{ selectedStore.minimumTicketFreeDelivery | number : "1.2-2" }}</span
          ></span
        >
        } @else {
        <span class="text-base">
          Yay! <span class="font-semibold">Free delivery</span> for this order
          <span>👍</span>
        </span>
        }
      </div>
    </div>

    <div class="self-stretch relative bg-gray-200 h-px"></div>
    <div
      class="w-[300px] self-stretch flex flex-row items-start justify-start gap-[0px_16px] text-sm text-brand-700"
    >
      <button
        *ngIf="products?.length > 0"
        (click)="viewCart()"
        class="rounded-lg bg-brand-50 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border overflow-hidden shrink-0 flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] border-[1px] border-solid border-brand-300"
      >
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

        <div class="flex flex-row items-center justify-center py-0 px-0.5 cursor-pointer">
          <div class="relative leading-[20px] font-semibold">View Cart</div>
        </div>
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
      </button>
      <button
        (click)="checkout()"
        *ngIf="products?.length > 0"
        class="flex-1 rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-white border-[1px] border-solid border-brand-600 cursor-pointer"
      >
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />

        <div class="flex flex-row items-center justify-center py-0 px-0.5">
          <div class="relative leading-[20px] font-semibold">Continue to Checkout</div>
        </div>
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" src="" />
      </button>
    </div>
  </div>
</div>
