import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ProductService } from '@app/product/services/product.service';
import { LoaderService } from '../services/loader.service';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailPageResolver implements Resolve<any> {
  selectedStore;
  user;
  constructor(
    private authService: AuthService,
    private productService: ProductService,
    private loaderService: LoaderService
  ) {
    this.authService.selectedStore.subscribe((data) => {
      if (data) {
        this.selectedStore = data;
      }
    });
    this.authService.currentUser.subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        this.user = data;
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const currentUrl = state.url; // Get the current URL
    const newUrl = route.url.join('/'); // Extract the new URL (if needed)
    if (newUrl !== currentUrl) {
      return of(null);
    } else {
      const slug = route.paramMap.get('slug');
      this.loaderService.open();
      if (this.selectedStore) {
        const params = {
          storeId: this.selectedStore.id,
          slug: slug,
        };
        if (this.user?.email) {
          return this.productService.getProductDetail(params);
        } else {
          return this.productService.getPublicProductDetail(params);
        }
      } else {
        return of(null);
      }
    }
  }
}
