<div id="carousel-example" class="relative w-full">
  <!-- Carousel wrapper -->
  <div class="relative overflow-hidden rounded-lg h-[600px]">
    <ng-container *ngIf="banners.length > 1; else placeholder">
      <div
        *ngFor="let banner of banners; let i = index"
        id="carousel-item-{{ i + 1 }}"
        class="hidden duration-5000 ease-in-out"
        data-carousel-item="active"
      >
        <div
          #bannerSlide
          [class.active]="i === activeSlideIndex"
          [style.background-image]="'url(' + banner.imageUrl + ')'"
          [style.width]="'auto'"
          [attr.data-image]="banner.imageUrl"
          class="full-screen-image slide flex duration-5000 ease-in-out w-full h-[600px] flex-col items-start justify-center py-[76px] px-6 md:px-16 box-border gap-[24px] bg-cover bg-no-repeat bg-[top] z-[2]"
        >
          <!-- <div
          class="flex duration-700 ease-in-out w-full h-[600px] flex-col items-start justify-center py-[76px] box-border gap-[24px] bg-cover bg-no-repeat bg-[top] z-[2]"
        >
          <img [src]="banner.imageUrl" class="absolute block w-full" />
        </div> -->
          <ng-container *ngIf="banner?.description">
            <div class="relative text-sm leading-[20px] font-semibold text-brand-600">
              {{ banner?.title }}
            </div>
            <h2
              class="w-[350px] md:w-[600px] relative text-29xl tracking-[-0.02em] leading-[60px] inline-block text-base-black"
            >
              {{ banner?.subtitle }}
            </h2>
            <div class="w-[600px] relative leading-[28px] text-gray-800 inline-block">
              {{ banner?.description }}
            </div>
            <span
              *ngIf="banner?.actionUrl || banner.collectionId"
              (click)="actionClick(banner)"
              class="rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-4 px-[22px] gap-[10px] text-base-white border-[1px] border-solid border-brand-600 cursor-pointer"
            >
              <img
                class="w-6 relative h-6 overflow-hidden shrink-0 hidden"
                alt="hidden icon"
                src=""
              />

              <div class="flex flex-row items-center justify-center py-0 px-0.5">
                <div class="relative leading-[28px] font-semibold">
                  {{ banner?.actionLabel || "Shop Now" }}
                </div>
              </div>
              <img
                class="w-6 relative h-6 overflow-hidden shrink-0"
                alt="arrow right"
                src="assets/images/home/arrowright.svg"
              />
            </span>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="banners.length === 1">
      <div
        *ngFor="let banner of banners; let i = index"
        id="carousel-item-{{ i + 1 }}"
        class="translate-x-full-one-slider hidden duration-5000 ease-in-out"
        data-carousel-item="active"
      >
        <div
          [class.active]="i === activeSlideIndex"
          [style.background-image]="'url(' + banner.imageUrl + ')'"
          [style.width]="'auto'"
          class="slide flex duration-5000 ease-in-out w-full h-[600px] flex-col items-start justify-center py-[76px] px-6 md:px-16 box-border gap-[24px] bg-cover bg-no-repeat bg-[top] z-[2]"
        >
          <!-- <div
          class="flex duration-700 ease-in-out w-full h-[600px] flex-col items-start justify-center py-[76px] box-border gap-[24px] bg-cover bg-no-repeat bg-[top] z-[2]"
        >
          <img [src]="banner.imageUrl" class="absolute block w-full" />
        </div> -->
          <ng-container *ngIf="banner?.description">
            <div class="relative text-sm leading-[20px] font-semibold text-brand-600">
              {{ banner?.title }}
            </div>
            <b
              class="w-[350px] md:w-[600px] relative text-29xl tracking-[-0.02em] leading-[60px] inline-block text-base-black"
            >
              {{ banner?.subtitle }}</b
            >
            <div class="w-[600px] relative leading-[28px] text-gray-800 inline-block">
              {{ banner?.description }}
            </div>
            <span
              *ngIf="banner?.actionUrl || banner.collectionId"
              (click)="actionClick(banner)"
              class="rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-4 px-[22px] gap-[10px] text-base-white border-[1px] border-solid border-brand-600 cursor-pointer"
            >
              <img
                class="w-6 relative h-6 overflow-hidden shrink-0 hidden"
                alt="hidden icon"
                src=""
              />

              <div class="flex flex-row items-center justify-center py-0 px-0.5">
                <div class="relative leading-[28px] font-semibold">
                  {{ banner?.actionLabel || "Shop Now" }}
                </div>
              </div>
              <img
                class="w-6 relative h-6 overflow-hidden shrink-0"
                alt="arrow right"
                src="assets/images/home/arrowright.svg"
              />
            </span>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-template #placeholder>
      <div class="placeholder full-screen-image"></div>
    </ng-template>
  </div>
  <!-- Slider indicators -->
  <div
    class="absolute bottom-5 left-1/2 z-[29] flex -translate-x-1/2 space-x-3 rtl:space-x-reverse"
  >
    <button
      *ngFor="let banner of banners; let i = index"
      id="carousel-indicator-{{ i + 1 }}"
      type="button"
      class="h-3 w-3 rounded-full"
      [attr.aria-label]="'Slide' + i"
      [attr.aria-current]="i === 0 ? 'true' : 'false'"
      (click)="changeActiveIndicator()"
    ></button>
    <!-- <button
      id="carousel-indicator-2"
      type="button"
      class="h-3 w-3 rounded-full"
      aria-current="false"
      aria-label="Slide 2"
    ></button>
    <button
      id="carousel-indicator-3"
      type="button"
      class="h-3 w-3 rounded-full"
      aria-current="false"
      aria-label="Slide 3"
    ></button>
    <button
      id="carousel-indicator-4"
      type="button"
      class="h-3 w-3 rounded-full"
      aria-current="false"
      aria-label="Slide 4"
    ></button> -->
  </div>
  <!-- Slider controls -->
  <button
    name="carousel-previous-btn"
    id="data-carousel-prev"
    type="button"
    aria-label="carousel-previous-btn"
    class="group absolute left-0 top-0 z-[29] flex h-full cursor-pointer items-center justify-center px-4 focus:outline-none"
  >
    <span
      class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70"
    >
      <svg
        class="h-4 w-4 text-white dark:text-gray-800"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 6 10"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 1 1 5l4 4"
        />
      </svg>
      <span class="hidden">Previous</span>
    </span>
  </button>
  <button
    name="carousel-next-btn"
    id="data-carousel-next"
    type="button"
    aria-label="carousel-next-btn"
    class="group absolute right-0 top-0 z-[29] flex h-full cursor-pointer items-center justify-center px-4 focus:outline-none"
  >
    <span
      class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70"
    >
      <svg
        class="h-4 w-4 text-white dark:text-gray-800"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 6 10"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 9 4-4-4-4"
        />
      </svg>
      <span class="hidden">Next</span>
    </span>
  </button>
</div>
