import { NewsletterService } from './../../services/newsletter.service';
import { NgIf } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { ValidatorService } from '@app/core/services/validator.service';
import { GenericService } from '@app/core/services/generics.service';

@Component({
  selector: 'app-newsletter',
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule],
  templateUrl: './newsletter.component.html',
  styleUrl: './newsletter.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class NewsletterComponent implements OnInit {
  isSubmitted = false;

  marketAppSettings;
  tenantUid;
  operationUid;
  isSuccess = false;

  constructor(
    private validators: ValidatorService,
    private genericService: GenericService,
    private newsletterService: NewsletterService
  ) {
    this.genericService.marketAppSetting.subscribe((data) => {
      if (data) {
        this.marketAppSettings = data;
        this.tenantUid = this.marketAppSettings.tenantUid;
        this.operationUid = this.marketAppSettings.operationUid;
      }
    });
  }

  newsletterForm = new FormGroup({
    newsletterEmail: new FormControl('', [this.validators.emailValidator, Validators.required]),
  });

  ngOnInit() {}

  onSubmit() {
    this.isSubmitted = true;

    const email = this.newsletterForm.get('newsletterEmail').value;

    if (this.newsletterForm.valid) {
      const params = {
        email: email,
        tenantUid: this.tenantUid,
        operationUid: this.operationUid,
      };

      this.newsletterService.saveNewsletterEmail(params).subscribe((result) => {
        this.isSuccess = true;

        setTimeout(() => {
          this.isSuccess = false;
          this.newsletterForm.reset();
          this.isSubmitted = false;
        }, 3000);
      });
    }
  }
}
