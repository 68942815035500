<!-- <button
  [id]="buttonId"
  data-dropdown-toggle="dropdown"
  class="rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] border-[1px] border-solid border-gray-300 hover:cursor-pointer"
  type="button"
>
  <img
    class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
    alt="filter lines"
    src="assets/images/category/filterlines.svg"
  />

  <div class="flex flex-row items-center justify-center py-0 px-0.5">
    <div class="relative leading-[20px] font-semibold">{{ label }}</div>
  </div>
  <img
    class="w-5 relative h-5 overflow-hidden shrink-0"
    alt="down icon"
    src="assets/images/category/chevrondown.svg"
  />
</button>

<div
  class="z-100 absolute top-[50px] hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
  [id]="menuId"
>
  <div
    class="w-80 relative rounded-lg bg-base-white shadow-[0px_12px_16px_-4px_rgba(16,_24,_40,_0.08),_0px_4px_6px_-2px_rgba(16,_24,_40,_0.03)] box-border overflow-hidden flex flex-row items-start justify-start border-[1px] border-solid border-gray-200"
    aria-labelledby="dropdownButton"
  >
    <section
      class="w-full my-0 px-0 box-border text-left text-base text-gray-900 font-text-md-regular cursor-pointer overflow-y-auto max-h-[500px]"
    >
      <div
        class="flex flex-col items-start justify-start py-px px-1.5"
        *ngFor="let option of optionsItems"
        (click)="onSelect(option)"
      >
        <header
          class="w-full rounded-md flex flex-row items-center justify-between py-2.5 pr-2.5 pl-2 gap-[0px_8px]"
          [class.bg-gray-50]="selectedFilters.includes(option.value)"
        >
          <div class="w-full relative leading-[24px] inline-block box-border pr-5">
            {{ option.label }}
          </div>
          <img
            class="h-5 w-5 relative overflow-hidden shrink-0 object-left"
            alt="checked icon"
            src="assets/images/category/check.svg"
            *ngIf="option?.selected"
          />
        </header>
      </div>
    </section>
  </div>
</div> -->

<button
  [id]="buttonId"
  data-dropdown-toggle="dropdown"
  class="rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] border-[1px] border-solid border-gray-300 hover:cursor-pointer"
  type="button"
>
  <img
    class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
    alt="filter lines"
    src="assets/images/category/filterlines.svg"
  />

  <div class="flex flex-row items-center justify-center py-0 px-0.5">
    <div class="relative leading-[20px] font-semibold">{{ label }}</div>
  </div>
  <img
    class="w-5 relative h-5 overflow-hidden shrink-0"
    alt="down icon"
    src="assets/images/category/chevrondown.svg"
  />
</button>

<div
  [id]="menuId"
  class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
>
  <ul
    class="py-2 text-sm text-gray-700 dark:text-gray-200 overflow-y-auto max-h-[500px]"
    aria-labelledby="dropdownDefaultButton"
  >
    @for(option of optionsItems; track option.label) {
    <li
      (click)="onSelect(option)"
      class="w-full rounded-md flex flex-row items-center justify-between px-4 py-2 cursor-pointer"
      [class.bg-gray-50]="selectedFilters.includes(option.value)"
    >
      <a class="">{{ option.label }}</a>
      <img
        class="h-5 w-5 relative overflow-hidden shrink-0 object-left"
        alt="checked icon"
        src="assets/images/category/check.svg"
        *ngIf="option?.selected"
      />
    </li>
    }
  </ul>
</div>
