<div
  [ngClass]="isWidthenable ? 'w-[242px]' : ''"
  class="rounded-lg bg-base-white box-border shrink-0 pt-0 px-0 pb-3 gap-[4px] z-[0] border-[1px] border-solid border-gray-2001 duration-700 ease-in-out overflow-hidden min-h-[275px] md:min-h-[340px]"
>
  <div class="flex items-center justify-center">
    <div
      class="self-stretch w-full h-60 overflow-hidden rounded-3xs p-[10px] flex flex-row relative gap-[0px_10px] bg-cover bg-no-repeat bg-[top]"
    >
      <img
        class="w-full h-full rounded-md object-cover"
        (click)="viewProduct(item)"
        loading="lazy"
        height="640"
        width="640"
        [src]="
          item?.productItem?.imageUrl
            ? item.productItem?.imageUrl
            : 'assets/images/home/no-image-icon.png'
        "
        alt="product image"
      />
      <ng-container *ngIf="!item?.productItem?.outOfStock">
        <button
          name="add-quantity-btn1"
          aria-label="add-quantity-btn1"
          class="disabled:opacity-75"
          [disabled]="item?.productItem?.outOfStock"
          type="button"
          *ngIf="!quantityCount"
          (click)="addQuantity(item)"
          class="w-[34px] disabled:opacity-75 absolute my-0 mx-[!important] top-[15px] left-[75%] md:left-[80%] h-[34px] z-[0] plus-sign cursor-pointer"
        >
          <div
            class="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-mid bg-brand-500"
          ></div>
          <img
            class="absolute h-[70.59%] md:w-[70.59%] top-[14.71%] right-[14.71%] bottom-[14.71%] left-[14.71%] max-w-full overflow-hidden max-h-full"
            alt="plus icon"
            src="assets/images/home/plus.svg"
          />
        </button>
      </ng-container>
      <ng-container *ngIf="!item?.productItem?.outOfStock">
        <div
          *ngIf="quantityCount > 0"
          class="absolute top-[15px] left-[75%] md:left-[80%] w-[34px] h-[34px]"
        >
          <div
            class="absolute h-full w-[276.47%] top-[0%] right-[0%] bottom-[0%] left-[-176.47%] rounded-mid bg-brand-500"
          ></div>
          <b class="absolute text-white top-[8.82%] left-[-55.12%] leading-[28px]">{{
            quantityCount
          }}</b>
          <button
            name="add-quantity-btn2"
            aria-label="add-quantity-btn2"
            class="disabled:opacity-75"
            [disabled]="item?.productItem?.outOfStock"
            (click)="addQuantity(item)"
          >
            <img
              class="absolute h-[70.59%] w-[70.59%] top-[14.71%] right-[14.71%] bottom-[14.71%] left-[14.71%] max-w-full overflow-hidden max-h-full cursor-pointer"
              alt="plus icon"
              src="assets/images/home/plus.svg"
            />
          </button>
          <button
            name="remove-quantity-btn"
            aria-label="remove-quantity-btn"
            class="disabled:opacity-75"
            [disabled]="item?.productItem?.outOfStock"
            (click)="removeQuantity(item)"
          >
            <img
              class="absolute h-[70.59%] w-[70.59%] top-[14.71%] right-[182.35%] bottom-[14.71%] left-[-152.94%] max-w-full overflow-hidden max-h-full cursor-pointer"
              alt="minus icon"
              src="assets/images/home/minus.svg"
            />
          </button>
        </div>
      </ng-container>
      <button
        name="add-to-favourite-btn"
        aria-label="add-to-favourite-btn"
        type="button"
        class="disabled:opacity-75"
        [disabled]="!user?.fullName || item?.productItem?.outOfStock"
        (click)="addToFavourite(item)"
      >
        <img
          class="w-7 absolute my-0 mx-[!important] top-[58px] left-[77%] md:left-[81.5%] h-7 overflow-hidden shrink-0 z-[1] cursor-pointer"
          alt="heart icon"
          [src]="
            item?.productItem?.isFavorite
              ? 'assets/images/home/heart-rounded-fill.svg'
              : 'assets/images/home/heartrounded.svg'
          "
        />
      </button>
      <div
        *ngIf="item?.productItem?.outOfStock"
        class="my-0 mx-[!important] absolute top-[10px] left-[10px] rounded-8xs bg-error-50 flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 gap-[4px] z-[1] border-[1px] border-solid border-error-200"
      >
        <div class="relative leading-[18px] font-semibold text-error-700 text-xs">Out of stock</div>
        <img
          class="w-3 relative h-3 overflow-hidden shrink-0"
          alt="ouofstock icon"
          src="assets/images/home/bellringing02.svg"
        />
      </div>
      <div
        *ngIf="item?.productItem?.isSpecialOffer"
        class="my-0 mx-[!important] absolute top-[10px] left-[10px] rounded-8xs bg-success-50 flex flex-row items-center justify-start py-0.5 pr-2 pl-1.5 gap-[4px] z-[1] border-[1px] border-solid border-success-200"
      >
        <img
          class="w-3 relative h-3 overflow-hidden shrink-0"
          alt="sale icon"
          src="assets/images/home/sale03.svg"
        />

        <div class="relative leading-[18px] font-semibold text-success-700 text-xs">
          Special Offer
        </div>
      </div>
      <div
        *ngIf="item?.productItem?.discount || item?.productItem?.discountInPercent"
        class="my-[-2px] mx-[!important] absolute top-[218px] left-[10px] rounded-8xs bg-brand-50 flex flex-row items-center justify-start py-0.5 px-2 z-[1] border-[1px] border-solid border-brand-200"
      >
        <div class="relative leading-[18px] font-semibold text-error-700 text-xs">
          {{
            item?.productItem?.discount
              ? item?.productItem?.discount
              : item?.productItem?.discountInPercent
          }}
          % Discount
        </div>
      </div>
    </div>
  </div>

  <div class="self-stretch flex flex-col items-start justify-start py-0 px-3 gap-[4px]">
    <div class="flex flex-row items-center justify-start gap-[8px] text-brand-500">
      <b class="relative leading-[28px] text-brand-500"
        >{{ configuration?.tenantCurrencySymbol
        }}{{ item?.productItem?.price | number : "1.2-2" }}</b
      ><span *ngIf="item?.productItem?.showWeight">({{ item?.productItem?.weight }} kg)</span>
      <div
        *ngIf="item?.productItem?.actualPrice && item?.productItem?.discountInPercent"
        class="relative text-sm [text-decoration:line-through] leading-[20px] font-semibold text-gray-400"
      >
        {{ configuration?.tenantCurrencySymbol
        }}{{ item?.productItem?.actualPrice | number : "1.2-2" }}
      </div>
    </div>

    <div
      (click)="viewProduct(item)"
      class="self-stretch relative text-sm leading-[20px] font-semibold"
    >
      {{ item?.productItem?.label }}
    </div>
    <div class="self-stretch relative text-xs leading-[18px]">
      {{ item?.productItem?.quantity }}
    </div>
  </div>
</div>
