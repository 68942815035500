import { APP_INITIALIZER, ApplicationConfig, isDevMode } from '@angular/core';
/// <reference types="@angular/localize" />
import { importProvidersFrom } from '@angular/core';
import { NgxLoadingModule } from 'ngx-loading';
import { provideRouter, RouterModule } from '@angular/router';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptorProvider } from '@app/core/interceptors/token.interceptor';
import { AuthenticationGuard } from '@app/core/guards/authentication.guard';
import { AuthenticatedLoginGuard } from '@app/core/guards/authenticatedLogin.guard';
import { AuthenticateURLGaurd } from '@app/core/guards/authenticateURL.gaurd';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { apiBaseUrl } from './core/services/api.service';
import { AppRoutes } from './app.routing';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { bannerReducer } from './store/reducers/banner.reducer';
import { pageTitlesReducer } from './store/reducers/pageTitles.reducer';
import { AppInitService } from './core/services/app-init.service';
import { alertsReducer } from './store/reducers/alerts.reducer';

export function initializeApp(appInitService: AppInitService): () => Promise<void> {
  return () => appInitService.loadAppConfig();
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AngularFireModule.initializeApp(environment.firebaseConfig),
      AngularFireAuthModule,
      NgxLoadingModule.forRoot({
        backdropBackgroundColour: 'rgba(0,0,0,0.1)',
        backdropBorderRadius: '4px',
        primaryColour: '#ff5f5f',
        secondaryColour: '#e64c4c',
        tertiaryColour: '#bf3636',
      }),
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      GoogleMapsModule,
      NgxImageZoomModule,
      CreditCardDirectivesModule,
      NgxStripeModule.forRoot(),
      NgxSliderModule,
      NgOtpInputModule,
      CalendarModule,
      ButtonModule,
      NgxPermissionsModule.forRoot(),
      ToastrModule.forRoot()
    ),
    {
      provide: apiBaseUrl,
      useValue: environment.eddressAppConfig.apiBaseUrl,
    },
    TokenInterceptorProvider,
    AuthenticationGuard,
    AuthenticatedLoginGuard,
    AuthenticateURLGaurd,
    provideAnimations(),
    provideRouter(AppRoutes),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore({ banners: bannerReducer, pages: pageTitlesReducer, alerts: alertsReducer }),
    provideEffects(),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true, // If set to true, the connection is established within the Angular zone
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
  ],
};
