<div
  class="self-stretch bg-gray-50 flex flex-row items-center justify-start py-8 px-6 md:px-16 gap-[10px] z-[3] text-11xl"
>
  <div class="flex-1 relative leading-[38px] font-bold">{{ title }}</div>
</div>
<div
  [ngClass]="!showArrowButtons ? 'pl-[30px]' : ''"
  class="self-stretch overflow-x-auto bg-gray-50 flex flex-row items-center justify-start py-0 pb-10 px-2 md:pr-0 relative cursor-pointer z-[4] text-gray-800 [&::-webkit-scrollbar]:hidden carousel-container"
  style="position: relative"
  id="frameContainerBestSellers"
  data-carousel="static"
  #frameContainerBestSellers
>
  <div
    *ngIf="showArrowButtons"
    class="arrow-left w-14 my-0 mx-[!important] rounded-9xl bg-base-white box-border h-14 shrink-0 hidden md:flex flex-row items-center justify-center z-[9] border-[1px] border-solid border-gray-2001"
    data-carousel-prev
    #previousButton
    (click)="scrollPrevious()"
  >
    <img
      class="w-6 relative h-6 overflow-hidden shrink-0"
      alt="arrow left"
      src="assets/images/home/arrowleft.svg"
    />
  </div>
  <div class="flex flex-row gap-[24px]">
    <ng-container *ngFor="let item of items">
      <app-product-item
        [item]="item"
        [isWidthenable]="true"
        (openAddressDrawer)="openAddressDrawerFn()"
        (openLoginDrawer)="openLoginDrawerFn()"
      />
    </ng-container>
  </div>
  <div
    *ngIf="showArrowButtons"
    class="arrow-right w-14 my-0 mx-[!important] absolute top-[117px] left-[1337px] rounded-9xl bg-base-white box-border h-14 shrink-0 hidden md:flex flex-row items-center justify-center z-[9] border-[1px] border-solid border-gray-2001"
    data-carousel-next
    #nextButton
    (click)="scrollNext()"
  >
    <img
      class="w-6 relative h-6 overflow-hidden shrink-0"
      alt="arrow right"
      src="assets/images/home/arrowright-2.svg"
    />
  </div>
</div>
