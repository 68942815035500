import { AppState } from '@app/app.state';
import { createSelector } from '@ngrx/store';
import { AlertsState } from '../states/alertsState';

export const selectAlertsState = (state: AppState) => state.alerts;

export const selectAlertssState = createSelector(
  selectAlertsState,
  (state: AlertsState) => state // Replace with the correct property
);

export const selectAlertsDataLoaded = createSelector(
  selectAlertsState,
  (state: AlertsState) => state.isLoaded // Boolean flag indicating if the data is loaded
);
