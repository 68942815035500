import { Routes } from '@angular/router';
import { CategoryComponent } from './category/category.component';
import { LandingComponent } from './landing/landing.component';
import { ProductComponent } from './product/product.component';
import { SearchComponent } from './search/search.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { ViewCartComponent } from './view-cart/view-cart.component';
import { ContentPageComponent } from './content-page/content-page.component';
import { UserInformationComponent } from './user-information/user-information.component';
import { MyAccountComponent } from './user-information/my-account/my-account.component';
import { OrdersComponent } from './user-information/orders/orders.component';
import { FavoritesComponent } from './user-information/favorites/favorites.component';
import { AddressesComponent } from './user-information/addresses/addresses.component';
import { PaymentsComponent } from './user-information/payments/payments.component';
import { MyWalletComponent } from './user-information/my-wallet/my-wallet.component';
import { ReferFriendComponent } from './user-information/refer-friend/refer-friend.component';
import { PrivacyPolicyComponent } from './content-page/privacy-policy/privacy-policy.component';
import { AuthenticationGuard } from './core/guards/authentication.guard';
import { AboutUsComponent } from './content-page/about-us/about-us.component';
import { ContactUsComponent } from './content-page/contact-us/contact-us.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { PaymentReturnComponent } from './user-information/payments/components/payment-return/payment-return.component';
import { environment } from '@environments/environment';
import { ProductDetailPageResolver } from './core/resolver/productDetailPage.resolver';
// Layouts

export const AppRoutes: Routes = [
  {
    path: '',
    data: { title: environment.eddressAppConfig.homePageTitle },
    component: LandingComponent,
  },
  {
    path: 'category/:slug',
    data: { breadcrumb: 'Collection', title: 'Category' },
    component: CategoryComponent,
  },
  {
    path: 'category/:slug/:group',
    data: { breadcrumb: 'Collection', title: 'Category' },
    component: CategoryComponent,
  },
  {
    path: 'product/:slug',
    data: { title: 'Product Detail' },
    component: ProductComponent,
    resolve: {
      productDetail: ProductDetailPageResolver,
    },
  },
  { path: 'search-products', data: { title: 'Search' }, component: SearchComponent },
  { path: 'checkout', data: { title: 'Checkout' }, component: CheckoutComponent },
  { path: 'order-detail/:id', data: { title: 'Order Detail' }, component: OrderDetailComponent },
  { path: 'view-cart', data: { title: 'View Cart' }, component: ViewCartComponent },
  { path: 'contents/:slug', data: { title: '' }, component: ContentPageComponent },
  {
    path: 'privacy-policy',
    data: { title: 'Privacy Policy' },
    component: PrivacyPolicyComponent,
    canActivate: [AuthenticationGuard],
  },
  { path: 'about-us', data: { title: 'About Us' }, component: AboutUsComponent },
  { path: 'contact-us', data: { title: 'Contact Us' }, component: ContactUsComponent },
  {
    path: 'payment-return',
    data: { title: 'Payment Return' },
    component: PaymentReturnComponent,
  },
  {
    path: 'user',
    component: UserInformationComponent,
    children: [
      { path: 'my-account', data: { title: 'My Account' }, component: MyAccountComponent },
      { path: 'orders', data: { title: 'Orders' }, component: OrdersComponent },
      { path: 'favorites', data: { title: 'Favorites' }, component: FavoritesComponent },
      { path: 'addresses', data: { title: 'Addresses' }, component: AddressesComponent },
      { path: 'payments', data: { title: 'Payments' }, component: PaymentsComponent },
      { path: 'my-wallet', data: { title: 'My Wallet' }, component: MyWalletComponent },
      { path: 'refer-friend', data: { title: 'Refer friend' }, component: ReferFriendComponent },
    ],
  },
  {
    path: '**',
    data: { title: '404 Page Not Found' },
    component: PageNotFoundComponent,
  },
];
